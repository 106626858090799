import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import LogoColor from "src/images/nav/logo/backbone_logo_fullColor.inline.svg"
import LogoSand from "src/images/nav/logo/backbone_logo_sand.inline.svg"

import {
  Link,
  Layout,
  Seo,
  SideBar,
  TextGroup,
  SideBarTestimonials,
} from "components/common"
import {
  useContentfulSection,
  useMeta,
  device,
  useContentfulComponent,
} from "utils"
import RequestDemo from "components/forms/RequestDemo"

const RequestADemoPage = ({ data: { request_a_demo } }) => {
  const {
    request_a_demo_form: { title, subtitle, components },
  } = useContentfulSection(request_a_demo.sections)

  const { testimonials, privacy_details } = useContentfulComponent(components)

  const meta = useMeta(request_a_demo)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <SideBar
          sideBar={() => <RenderTestimonials testimonials={testimonials} />}
        >
          <div className="logoWrap">
            <Link to="/">
              <LogoSand className="desktop" />
              <LogoColor className="mobile" />
            </Link>
          </div>
          <FormWrap>
            <TextGroup title={title} subtitle={subtitle} />
            <RequestDemo privacy_details={privacy_details} />
          </FormWrap>
        </SideBar>
      </Wrap>
    </Layout>
  )
}

const RenderTestimonials = React.memo(({ testimonials }) => (
  <TestminialWrap>
    <SideBarTestimonials testimonials={testimonials} isVisible />
  </TestminialWrap>
))

const Wrap = styled.div`
  background: white;
  padding-top: var(--sp-mobile-nav);
  @media ${device.laptop} {
    padding-top: 0;
  }

  @media ${device.desktopL} {
    height: calc(var(--vh, 1vh) * 100);
  }

  .logoWrap {
    position: absolute;
    left: var(--sideBar-mobile_padding);
    top: var(--sideBar-mobile_padding);

    @media ${device.laptop} {
      top: 32px;
      left: 48px;
    }

    .desktop {
      display: none;
      @media ${device.laptop} {
        display: inline-block;
      }
    }

    .mobile {
      display: inline-block;
      @media ${device.laptop} {
        display: none;
      }
    }
  }
`

const FormWrap = styled.div`
  background: white;
  display: grid;
  grid-gap: var(--sp-24);
  width: 100%;

  @media ${device.laptop} {
    max-width: 450px;
  }
`

const TestminialWrap = styled.div``

export const query = graphql`
  {
    request_a_demo: contentfulPage(pageId: { eq: "request_a_demo" }) {
      ...Page
    }
  }
`

export default RequestADemoPage
