import React, { useLayoutEffect, useRef } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { yupResolver } from "@hookform/resolvers/yup"

import * as yup from "yup" // for everything

import { Button, TextGroup } from "components/common"

import { Input, Phone, Select } from "components/inputs"

import { useLocalState, device } from "utils"

import stateSelectOptions from "./stateSelectOptions"

const defaultValues = {
  first_name: "",
  last_name: "",
  email: "",
  company: "",
  "00N3t00000GdStS": undefined,
  phone: "",
  year: "", // honeypots
}

const schema = yup.object().shape({
  first_name: yup.string().trim().required("First Name is required"),
  last_name: yup.string().trim().required("Last Name is required"),
  email: yup
    .string()
    .trim()
    .email("Valid email is required")
    .required("Email is required"),
  company: yup.string().trim().required("Company is required"),
  phone: yup
    .string()
    .trim()
    .matches(/^(?:(\d{3})|\d{3})[- .]?\d{3}[- .]?\d{4}$/, {
      message: "Phone number is not valid",
      excludeEmptyString: true,
    })
    .required("Phone number is required."),
  // state: yup.string().trim().required("State or province is required"),
  "00N3t00000GdStS": yup
    .object()
    .shape({
      value: yup.string().required("State or province is required"),
      label: yup.string().required("State or province is required"),
    })
    .nullable()
    .required("State or province is required"),
})

const RequestDemo = ({ privacy_details }) => {
  const isProd =
    process.env.NODE_ENV === "production" &&
    process.env.GATSBY_ENV === "production"

  const formRef = useRef()
  const {
    loading,
    success,
    error,
    setLoading,
    // setSuccess,
    // setError,
    resetLocalState,
  } = useLocalState()

  const {
    handleSubmit,
    register,
    // reset,
    control,

    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (values) => {
    resetLocalState()
    setLoading(true)
    const year = document.getElementById("year")
    if (!values.year) {
      year.parentNode.removeChild(year)
      formRef.current.submit()
    }
  }

  // window add event listner doesnt work on page change?? so add some code here...
  useLayoutEffect(() => {
    if (typeof window !== undefined) {
      var currGclidFormField = document.getElementById("00N3t00000GdAWu")
      var gclid = JSON.parse(localStorage.getItem("gclid"))
      var isGclidValid = gclid && new Date().getTime() < gclid.expiryDate
      if (currGclidFormField && isGclidValid) {
        currGclidFormField.value = gclid.value
      }
    }
  }, [])

  return (
    <Form
      id="wf-form-Demo-Form"
      name="wf-form-Demo-Form"
      data-name="Demo Form"
      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
      method="post"
      className="form-2"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      ref={formRef}
    >
      <div className="horizontalWrap">
        <div className="fullName">
          <Input
            id="first_name"
            name="first_name"
            data-name="first_name"
            errors={errors}
            placeholder="First name"
            label="First name"
            register={register}
          />
          <Input
            id="last_name"
            name="last_name"
            data-name="last_name"
            errors={errors}
            placeholder="Last name"
            label="Last name"
            register={register}
          />
        </div>
        <Input
          id="company"
          name="company"
          data-name="company"
          errors={errors}
          label="Company"
          placeholder="Company"
          register={register}
        />
        <Input
          id="email"
          className="email"
          errors={errors}
          type="email"
          name="email"
          label="Email"
          placeholder="email@email.com"
          register={register}
        />

        <Select
          id="00N3t00000GdStS"
          name="00N3t00000GdStS"
          data-name="00N3t00000GdStS"
          title="location"
          errors={errors}
          label="State or province"
          placeholder="State or province"
          control={control}
          options={stateSelectOptions}
        />

        {/* <Input
          id="state"
          name="state"
          data-name="state"
          errors={errors}
          label="State or province"
          placeholder="State or province"
          register={register}
        /> */}
        <Phone
          id="phone"
          name="phone"
          data-name="phone"
          errors={errors}
          label="Phone number"
          placeholder="650-650-6500"
          required
          control={control}
        />

        <input
          id="oid"
          type="hidden"
          name="oid"
          value="00D3t000002Pd9u"
        ></input>

        {!isProd && (
          <>
            {/* debug */}
            <input type="hidden" name="debug" value="1" />
            <input
              type="hidden"
              name="debugEmail"
              value="drew@secretsauce.design"
            />
            <input type="hidden" name="retURL" value="http://localhost:8000/" />
            {/* ******* */}
          </>
        )}

        <input
          type="hidden"
          className="sourceLogging"
          id="page_source"
          name="formSrc1"
          data-name="Page Source"
          value=""
        />
        <input
          type="hidden"
          className="sourceLogging"
          id="click_source"
          name="formSrc1"
          data-name="Click Source"
          value=""
        />
        <input
          type="hidden"
          className="sourceLogging"
          id="utm_source"
          name="formSrc1"
          data-name="UTM Source"
          value=""
        />
        <input
          type="hidden"
          className="sourceLogging"
          id="utm_medium"
          name="formSrc1"
          data-name="UTM Medium"
          value=""
        />
        <input
          type="hidden"
          className="sourceLogging"
          id="utm_campaign"
          name="formSrc1"
          data-name="UTM Campaign"
          value=""
        />
        <input
          type="hidden"
          className="sourceLogging"
          id="utm_content"
          name="formSrc1"
          data-name="UTM Content"
          value=""
        />

        <input
          type="hidden"
          name="retURL"
          value="https://www.backboneiq.com/success"
        />

        <input
          type="hidden"
          id="lead_source"
          name="lead_source"
          data-name="Lead Source"
          value="Inbound"
        />

        <input
          id="00N3t00000GdAWu"
          name="00N3t00000GdAWu"
          value=""
          type="hidden"
        />

        <Button
          type="submit"
          className="submit"
          disabled={isSubmitting || loading}
        >
          {loading ? "Please wait..." : "Get A Demo"}
        </Button>

        {privacy_details && (
          <TextGroup
            className="privacy_details"
            title={privacy_details.title}
            titleAs="p"
          />
        )}
      </div>

      {success && <p className="success">Thanks for your interest</p>}
      {error && (
        <p className="error">
          {error.message ||
            "Sorry, unknown error occured, please try again later."}
        </p>
      )}

      <Input
        errors={errors}
        className="year"
        id="year"
        type="text"
        name="year"
        placeholder="year"
        register={register}
      />
    </Form>
  )
}

const Form = styled.form`
  /* border: 1px solid red;
  padding: 40px; */

  .horizontalWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-12);
    width: 100%;

    .fullName {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-12);

      @media ${device.laptop} {
        grid-gap: var(--sp-16);
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  .success,
  .error {
    text-align: center;
    color: var(--form-success-color);
    margin-top: var(--sp-16);
    font-weight: 500;
  }

  .error {
    color: var(--form-error-color);
  }

  .year-wrap {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -99;
    height: 0;
  }

  .submit {
    margin-top: var(--sp-16);
  }

  .privacy_details {
    margin-top: var(--sp-8);
    .title,
    a {
      font-size: 0.875rem;
      line-height: 20px;
    }
  }
`

export default RequestDemo
